.comp-footer {
  width: 100%;
  height: 334px;
  background: #D90B17;


  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 120px);
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 48px;

    .logo {
      display: block;
      width: 154px;
      height: auto;
    }

    .related-box {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .related-item {
        width: 130px;
        box-sizing: content-box;

        .item-title {
          font-weight: bold;
          font-size: 22px;
          color: #FFFFFF;
          padding-bottom: 20px;
        }

        .item-desc {
          display: block;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 30px;
        }

        .qr-code {
          display: block;
          width: 107px;
          height: auto;
          margin: 0 auto 4px auto;
        }
      }
    }
  }


  .copy-right {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}