.page-index {
  width: 100%;

  .banner-box {
    position: relative;
    overflow: hidden;

    .banner {
      display: block;
      width: 100%;
      height: auto;

      transform: translate(0);
      -webkit-animation: lateAnimate 3s linear infinite alternate;
      animation: lateAnimate 3s linear infinite alternate;
    }

    @keyframes lateAnimate {
      0% {
          transform: scale(1) translate(0)
      }
  
      to {
          transform: scale(1.2) translate(1.052632rem,.105263rem)
      }
    }
    
    @-webkit-keyframes lateAnimate {
        0% {
            transform: scale(1) translate(0)
        }
    
        to {
            transform: scale(1.2) translate(1.052632rem,.105263rem)
        }
    }

    .title-box {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);

      .title {
        height: 132px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 100px;
        color: #FFFFFF;
        text-align: justify;
        overflow: hidden;
      }

      .sub-title {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        text-align: justify;
        // letter-spacing: 4px;
      }

      .title::after,
      .sub-title::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }

  .about-us {
    position: relative;
    width: calc(100% - 48px);
    max-width: 1200px;
    background: #FFFFFF;
    padding: 73px 0 37px 0;
    margin: 0 auto;

    .text-content {
      position: absolute;
      top: 73px;
      width: 100%;
      height: 100%;

      .english-title {
        font-family: PingFang-SC-Medium;
        font-size: 20px;
        color: #999999;
        padding-bottom: 12px;
      }

      .title {
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        font-size: 34px;
        color: #222222;
        padding-bottom: 32px;
      }

      .desc {
        font-family: PingFang-SC-Medium;
        font-size: 20px;
        color: #666666;
        line-height: 36px;
        padding-bottom: 28px;
      }

      .more-btn {
        display: inline-block;
        background: #D90B17;
        height: 45px;
        font-family: PingFang-SC-Medium;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 45px;
        padding: 0 42px;
        border-radius: 23px;
      }
    }

    .about-us-bg-box {
      width: 1048px;
      height: 566px;
      margin-top: 90px;
      margin-left: auto;

      .about-us-bg {
        display: block;
        width: 1048px;
        height: auto;
      } 
    }

    .location-list-static {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .location-list {
        position: relative;
  
        .location-icon {
          position: absolute;
          width: 20px;
          height: auto;
          display: block;
  
          .location-icon-image {
            display: block;
            width: 20px;
            height: auto;
            opacity: 0;
          }
        }
  
        .location-1 {
          top: 516px;
          right: 906px;
          animation-delay: 0.5s;
        }
  
        .location-2 {
          top: 362px;
          right: 750px;
          animation-delay: 0.75s;
        }
  
        .location-3 {
          top: 528px;
          right: 716px;
          animation-delay: 1s;
        }
  
        .location-4 {
          top: 330px;
          right: 578px;
          animation-delay: 1.25s;
        }
  
        .location-5 {
          top: 508px;
          right: 534px;
          animation-delay: 1.5s;
        }
  
        .location-6 {
          top: 222px;
          right: 366px;
          animation-delay: 1.75s;
        }
  
        .location-7 {
          top: 300px;
          right: 100px;
          animation-delay: 2s;
        }
  
        // 兼容因为margin越来越小的错位
        @media screen and (max-width: 1096px) {
          .location-1 {
            top: 516px;
            right: auto;
            left: 122px;
          }
    
          .location-2 {
            top: 362px;
            right: auto;
            left: 278px;
          }
    
          .location-3 {
            top: 528px;
            right: auto;
            left: 310px;
          }
    
          .location-4 {
            top: 330px;
            right: auto;
            left: 448px;
          }
  
          .location-5 {
            top: 508px;
            right: auto;
            left: 494px;
          }
    
          .location-6 {
            top: 222px;
            right: auto;
            left: 660px;
          }
    
          .location-7 {
            top: 300px;
            right: auto;
            left: 928px;
          }
        }
      }
    }
  }

  .core-business-mode {
    position: relative;

    .core-business-mode-bg {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: auto;
    }

    .text-content {
      position: relative;
      display: block;
      width: calc(100% - 48px);
      max-width: 1200px;
      margin: 0 auto 0 auto;

      .english-title {
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        padding-top: 100px;
      }

      .title {
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        font-size: 34px;
        color: #FFFFFF;
        padding-bottom: 24px;
      }

      .desc {
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 36px;
        padding-bottom: 18px;
      }

      .core-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 78px;

        .core-item {
          position: relative;
          width: 25%;
          height: 460px;
          padding: 50px 40px;
          cursor: pointer;
          background: #8eb5f5;
          background-size: cover;
          transition: .8s;

          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title-text {
              font-family: PingFang-SC-Bold;
              font-weight: bold;
              font-size: 30px;
              color: #FFFFFF;
            }
            .icon-more {
              font-size: 30px;
            }
          }

          .core-desc {
            display: none;
            font-family: PingFang-SC-Regular;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 30px;
            padding-top: 40px;
            opacity: 0;
          }

          .selling-points-list {
            position: absolute;
            bottom: 50px;
            width: 100%;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            opacity: 0;

            .points-item {
              width: 100%;
              text-align: center;

              .item-title {
                font-family: PingFang SC;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 30px;
              }

              .item-sub-title {
                font-family: PingFang-SC-Medium;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 36px;
              }
            }
          }

          .warehouse-list {
            position: absolute;
            bottom: 96px;
            width: 100%;
            padding: 0 40px;
            left: 0;
            display: flex;
            justify-content: space-around;
            width: 100%;
            opacity: 0;

            .warehouse-item {
              font-family: PingFang SC;
              font-weight: bold;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 30px;
            }
          }

          .warehouse-desc {
            position: absolute;
            bottom: 52px;
            width: 100%;
            padding: 0 40px;
            left: 0;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            color: #FFFFFF;
            line-height: 36px;
            opacity: 0;
          }
        }

        .active {
          transition: .8s;
          width: 50%;
          background-image: none !important;
          background: #D90B17;

          .core-desc {
            transition: 2s;
            opacity: 1;
            display: block;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 5;   // 显示的行数 
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .selling-points-list, .warehouse-list, .warehouse-desc  {
            transition: 2s;
            opacity: 1;

            .points-item {

              .item-title {
                transition: 0.8s;
                font-size: 30px;
              }

              .item-sub-title {
                transition: 0.8s;
                font-size: 20px;
              }
            }

            .warehouse-item {
              font-size: 30px;
              transition: 0.8s;
            }
          }
        }
      }

      .self-operated-platform {
        text-align: center;
        padding-bottom: 80px;
    
        .platform-english-title {
          font-family: PingFang-SC-Medium;
          font-size: 20px;
          color: #999999;
        }
        .platform-title {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 34px;
          color: #222222;
          padding-top: 4px;
          padding-bottom: 36px;
        }
    
        .platform-list {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 20px 0;
          cursor: pointer;
    
          .platform-logo {
            display: block;
            margin: 0 30px;
          }
        }

        .platform-list:hover {
          background: #EFF7FF;
        }
      }
    }
  }
}
