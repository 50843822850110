.comp-header {
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  height: 54px;
  background: transparent;
  z-index: 1;

  .container {
    display: flex;
    justify-content: flex-start;
    width: calc(100vw - 48px);
    max-width: 1200px;
    margin: 0 auto;

    .logo {
      display: block;
      width: 154px;
      height: 42px;
      margin-right: 40px;
      flex-shrink: 0;
    }
    
    .menu-box {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .menu-item {

        .menu-name {
          display: block;
          font-family: Source Han Sans SC;
          font-size: 20px;
          color: #FFFFFF;
          line-height: 44px;
        }

        .menu-name-active {
          font-weight: bold;
        }

        .active-sign {
          width: 100%;
          height: 4px;
          background: #FFFFFF;
          border-radius: 2px;
        }
      }
    }
  }
}